
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation, Loading } from "@/utils/decorator";
import FileUpload from "@/components/scope/file-upload.vue";
import t from "@common/src/i18n/t";
const announcement = namespace("announcement");
const base = namespace("base");
const consult = namespace("consult");
const fission = namespace("fission");
const management = namespace("management");

@Component({
  components: { TableEnableFlag, FileUpload }
})
@Pageniation("loadListData")
export default class FissionBill extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @announcement.Action getAnnouncementList;
  @announcement.Action endAnnouncement;
  @management.Action getStoreList;
  @consult.Action queryUserAccountList;
  @fission.Action queryOpReceiptPage;
  @fission.Action settlement;
  @fission.Action refundOrder;

  dayjs = dayjs;
  // visible: boolean = true;
  row = {
    paymentAmount: undefined,
    billAmount: undefined,
    settlementStatus: undefined
  };
  searchForm = {
    title: "", //公告名称
    announcementCode: "", //公告编号
    status: "",
    startPaymentTimeStr: "",
    endPaymentTimeStr: "",
    announcementRange: "", //发布范围
    publisher: "", //发布人
    settlementStatus: ""
  };

  insertForm = {
    bankVoucherAmount: undefined,
    bankVoucher: undefined,
    fissionOrderList: [],
    paymentSerialNumber: undefined
  };
  twiceRule(rule, value, callback) {
    console.log("[ this.row.settlementStatus ]", this.row.settlementStatus);
    if (this.row.settlementStatus === "30") {
      callback();
    } else if (Number(value) !== Number(this.row.billAmount || this.row.paymentAmount)) {
      callback(new Error(t("v210831.the-bill-amount")));
    } else {
      callback();
    }
  }
  bankAmountRule = (rule, value, callback) => {
    if (value) {
      this.twiceRule(rule, value, callback);
    } else {
      callback(new Error(t("v210831.please-enter-bank-proof-amount")));
    }
  };
  insertFormRule = {
    bankVoucherAmount: [
      { required: true, message: t("v210831.please-enter-bank-proof-amount"), trigger: "blur" },
      { validator: this.bankAmountRule, trigger: "blur" }
    ],
    bankVoucher: [{ required: true, message: t("v210831.please-upload-payment-proof"), trigger: "blur" }],
    paymentSerialNumber: [{ required: true, message: t("v210831.please-enter-bank-number"), trigger: "blur" }]
  };
  defaultBannerList: any = [];
  dialogFormVisible = false;
  emptyToLine = emptyToLine;
  announcementList: any[] = [];
  storeTypeList: any[] = [];
  publisherList: any = [];
  dialogReturn = false;
  refundForm = {
    remark: undefined,
    fissionOrderNo: undefined,
    refundAmount: undefined
  };
  refundRules = {
    remark: [{ required: true, message: t("v210831.please-enter-refund"), trigger: "blur" }]
  };
  lookView(row) {
    console.log("[ row ]");
  }
  handleReturnRefund() {
    let refs = this.$refs["refundForm"] as any;
    refs.validate(valid => {
      if (valid) {
        let data = {
          ...this.refundForm
        };
        this.refundOrder(data).then(res => {
          this.$message.success(t("v210831.refund-operation-succeeded"));
          this.dialogReturn = false;
          this.loadListData();
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  handleInsert() {
    let refs = this.$refs["insertForm"] as any;
    refs.validate(valid => {
      if (valid) {
        let data = {
          ...this.insertForm
        };
        this.settlement(data).then(res => {
          this.$message.success(t("v210831.settlement-succeeded"));
          this.dialogFormVisible = false;
          this.loadListData();
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  storeList: any = [];
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  get searchList() {
    return [
      {
        label: t("v210831.store-name"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: t("v210801.order-number"),
        type: "input",
        value: "",
        prop: "fissionOrderNo"
      },
      {
        label: t("base.settlement-status"),
        type: "select",
        selectList: this.statusList,
        value: "",
        prop: "dataValue"
      },
      {
        label: t("v210801.payment-time"),
        type: "timerange",
        value: "",
        prop: "time"
      },
      {
        label: t("finance.serial-number"),
        type: "input",
        value: "",
        prop: "paymentSerialNumber"
      },
      {
        label: t("finance.guest-names"),
        type: "input",
        value: "",
        prop: "customerName"
      },
      {
        label: t("finance.mobile"),
        type: "input",
        value: "",
        prop: "customerMobile"
      }
    ];
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  handlefinished(row) {
    this.$router.push(`/management/fission-bill/bill-batch/${row.storeCode}`);
  }
  handleOne(row) {
    console.log("[ row ]", row);
    this.row = row;
    this.defaultBannerList = [];
    this.dialogFormVisible = true;
    if (row.settlementStatus === "30") {
      this.insertForm.bankVoucherAmount = row.bankVoucherAmount;
      this.insertForm.bankVoucher = row.bankVoucher;
      setTimeout(() => {
        this.defaultBannerList = [{ url: row.bankVoucher }];
      }, 0);
      this.insertForm.paymentSerialNumber = row.paymentSerialNumber;
    } else {
      this.insertForm.bankVoucherAmount = undefined;
      this.insertForm.bankVoucher = undefined;
      this.insertForm.paymentSerialNumber = undefined;
    }
    this.insertForm.fissionOrderList = [row.fissionOrderNo];
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "time") {
          this.searchForm.startPaymentTimeStr = item.value ? item.value[0] : "";
          this.searchForm.endPaymentTimeStr = item.value ? item.value[1] : "";
        } else if (item.prop === "dataValue") {
          this.searchForm.settlementStatus = item.value;
        }
        this.searchForm[item.prop] = item.value;
      }
    });
    console.log(this.searchForm, "searchfrom");
    if (
      this.searchForm.startPaymentTimeStr !== "" &&
      this.searchForm.startPaymentTimeStr === this.searchForm.endPaymentTimeStr
    ) {
      this.$message.warning(t("v210831.the-start-and"));
      return;
    }
    this.loadListData();
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.queryOpReceiptPage({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.announcementList = data.data.list;
      //   console.log(data, "data");
      return data;
    });
  }
  statusList = [];
  async init() {
    this.loadListData();
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    this.getDictionaryList("fission_settlement_status").then(data => {
      this.statusList = data;
    });
    //获取发布人列表
    this.getUserAccount();
  }
  getUserAccount() {
    this.queryUserAccountList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.publisherList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.firstName;
            item.value = item.firstName;
            return item;
          })
        : [];
    });
  }
  handleEdit(row) {
    this.dialogReturn = true;
    this.row = row;
    this.refundForm.fissionOrderNo = row.fissionOrderNo;
    this.refundForm.refundAmount = row.paymentAmount;
    this.refundForm.remark = undefined;
    // this.$router.push(`/management/announcement-edit/${row.announcementCode}`);
  }
}
